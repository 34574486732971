.img-itinerario{
  margin: 0;
  padding: 0;
  height: 57px;
  width: 57px;
  margin: 15px 0;
}

.title-itinerario{
  font-family: 'Cormorant Infant', serif;
  font-weight: 700;
}

  
.h2-itinerario{
  display: flex;
  justify-content: center;
  margin: 0;
}

.itinerario{
  display: flex;
  flex-direction: column;
  margin: 0px 0;
  
}

.cointainer-itinerario{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  width: 100%;
  padding: 0 15px;
}

.cointainer-itinerario p b{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Cormorant Infant', serif;
  font-weight: 700;
}

.address-itinerario{
  margin: 15px 0;

}

center{
font-family: 'Didact Gothic';
}

.time-itinerario{
  margin: 15px 0;
  font-family: 'Didact Gothic';
  letter-spacing: 1px;
}


.a-itinerario{
fill: #f3f1f1;
color: #f3f1f1;
background-color: #b28c5d;
border-radius: 0px 0px 0px 0px;
padding: 20px 20px 20px 20px;
text-decoration: none;
margin: 10px 0;
}

.map-button{
  font-family: 'Didact Gothic';
  font-weight: 600;
  letter-spacing: 1px;
}



@media screen and (min-width: 770px){
  .itinerario{
    display: flex;
    flex-direction: row;
    padding: 0px 100px;
    /* max-width: 1024px; */
  }
  .cointainer-itinerario{
    width: 33.333%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding: 14px 10px;
  }


}


