/*--------------------------------------------------------------
# Mesa Section
--------------------------------------------------------------*/
#mesa {
  padding: 60px 0;
}

.mesa-container{
  height: 400px;
  padding: 90px 0;
}

@media (max-width: 767px) {
  .mesa-container{
    height: auto;
    padding: 40px 47px;
  }
}

.mesa-container h3{
  font-family: 'Cormorant Infant', serif;
}

.mesa-container p{
  font-family: 'Didact gothic';
}

.phrase-gift{
  text-transform: uppercase;
}

.gift-img{
  height: 50px;
  width: 50px;
}


#mesa button{
  font-family: 'Didact Gothic';
  font-weight: 600;
  letter-spacing: 1px;
}

.a-mesabutton{
  fill: #f3f1f1;
  color: #f3f1f1;
  background-color: #b28c5d;
  border-radius: 0px 0px 0px 0px;
  padding: 20px 20px 20px 20px;
  text-decoration: none;
  margin: 10px 0;
}
  


 

  

 



  