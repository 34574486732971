/*--------------------------------------------------------------
# padrinos Section
--------------------------------------------------------------*/

#padrinos .nav-tabs {
  text-align: center;
  margin: auto;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
  margin-right: 50px;
}
#padrinos .nav-tabs li {
  display: inline-block;
  margin-bottom: 0;
}
#padrinos .nav-tabs a {
  /* border: none;
  border-radius: 50px;
  font-weight: 600;
  background-color: #e0aca2;
  color: #fff;
  padding: 10px 100px; */

  border: none; 
  font-weight: 600;
  background-color: #b28c5d;
  color: #fff;
  margin-left: 47px;
  padding: 12px 46px;
  width: 227px;
  height: 55px;

}



.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


@media (max-width: 991px) {
  #padrinos .nav-tabs a {
    padding: 8px 60px;
    margin-top: 4px;
  }
}
@media (max-width: 767px) {
  #padrinos .nav-tabs a {
    padding: 8px 50px;
  }

  #padrinos .nav-tabs{
    margin-right: 28px;
  }
}
@media (max-width: 480px) {
  #padrinos .nav-tabs a {
    padding: 8px 30px;
  }
}
#padrinos .nav-tabs a.active {
  background-color: #8c6a37;
}

#padrinos .sub-heading {
  text-align: center;
  font-size: 18px;
  font-style: italic;
  margin: 0 auto 30px auto;
}
@media (min-width: 991px) {
  #padrinos .sub-heading {
    width: 75%;
  }
}
#padrinos .tab-pane {
  transition: ease-in-out 0.2s;
}
#padrinos .padrinos-item {
  /* border-bottom: 1px solid #cad4f6; */
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background-color ease-in-out 0.3s;
}
#padrinos .padrinos-item:hover {
  background-color: #fff;
}
#padrinos .padrinos-item time {
  padding-bottom: 5px;
  display: inline-block;
}
#padrinos .padrinos-item .speaker {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  float: left;
  margin: 0 10px 10px 0;
}
#padrinos .padrinos-item .speaker img {
  /* height: 100%; */
  height: 84%;
  transform: translateX(-50%);
  margin-left: 50%;
  transition: all ease-in-out 0.3s;
}
#padrinos .padrinos-item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: 'Cormorant Infant', serif;
  font-weight: 700;
  color: black;
}

#padrinos .padrinos-item p {
  font-style: italic;
  color: #464850;
  margin-bottom: 0;
}