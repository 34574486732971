.button-to-home{
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8c6a37;

    position: absolute;
    bottom: 12px;
    right: 12px;
    height: 45px;
    width: 45px;
  }

  @media (max-width: 967px) { 
    .home-icon{
        width: 25px;
        height: 25px;
    }
    

    .button-to-home{
        height: 40px;
        width: 40px;
    }


}

@media (max-width: 467px) { 
    .home-icon{
        width: 20px;
        height: 20px;
    }
    

    .button-to-home{
        height: 30px;
        width: 30px;
    }


}