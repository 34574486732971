video {
  position: absolute; 
  object-fit: cover; 
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overscroll-behavior: contain;
}  
  
.text-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 100vh;
  position: fixed;
}

.text-video  h1 {
  color: aliceblue;
  font-size: 55px;
  font-family: 'Alex Brush', cursive;
  font-weight: 600;
}

.text-video  h3{
  color: aliceblue;
  font-weight: 200;
  font-size: 14px;
  font-family: 'Didact Gothic', sans-serif;
  letter-spacing: 3px;

}

.text-video h2{
  color: aliceblue;
  font-size: 35px;
  font-family: 'Parisienne', cursive;
}

.text-video  h6{
  color: white;
  font-family: 'Didact Gothic', sans-serif;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 12px;

}

/* .audio{
  display: flex;
} */

@media screen and (min-width: 390px){
  .text-video h1 {
    font-size: 70px;
  }
  
  .text-video h3{
    font-size: 20px;
  
  }

  .text-video h2{
    font-size: 35px;
  
  }

  .text-video h6{
    font-size: 15px;

  }

}


@media screen and (min-width: 500px){
  .text-video h1 {
    font-size: 80px;
  }
  
  .text-video h3{
    font-size: 22px;
  
  }

  .text-video h2{
    font-size: 45px;
  
  }

}

@media screen and (min-width: 700px){
  .text-video h1 {
    font-size: 90px;
  }
  
  .text-video h3{
    font-size: 24px;
  
  }

  .text-video h2{
    font-size: 55px;
  
  }

  .text-video h6{
    font-size: 18px;

  }
  

}

@media screen and (min-width: 1024px){
  .text-video h1 {
    font-size: 100px;
  }
  
  .text-video h3{
    font-size: 25px;
  
  }

  .text-video h2{
    font-size: 70px;
  
  }

  .text-video h6{
    font-size: 20px;

  }
  

}