/*--------------------------------------------------------------
# DressCode Section
--------------------------------------------------------------*/
#dressCode {
    padding: 60px 0;
  }

.dress{
    background-color: #F3F1F1;;
}
.contact-info{
    padding: 0 150px;
}
.dress-code{
    /* background-color: #8b8b8d; */
    height: 400px;
    padding: 20px 0;
}
.dress-code-sect div {
  font-family: 'Didact gothic';
}
.no-child{
  letter-spacing: 1px;
}
.hashtag{
    /* background-color: #393941; */
    /* padding: 0px 20px; */
    height: 400px;
    padding: 20px 0;
}
.hash-sect{
  font-family: 'Didact gothic';
}
  #dressCode .contact-info {
    margin-bottom: 20px;
    text-align: center;
  }
  #contaasistenciact .contact-info i {
    font-size: 48px;
    display: inline-block;
    margin-bottom: 10px;
    color: #e0aca2;
  }
  #dressCode .contact-info address, #dressCode .contact-info p {
    margin-bottom: 0;
    color: #1f1f22;
  }
  #dressCode .contact-info h3 {
    font-size: 23px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #a8835a;
    font-family: 'Cormorant Infant', serif;
  }
  #dressCode .contact-info a {
    color: #1f1f22;
  }
  #dressCode .contact-info a:hover {
    color: #e0aca2;
  }
  #dressCode .dress-code-sect, #dressCode .contact-phone, #dressCode .hash-sect {
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    #dressCode .dress-code-sect, #dressCode .contact-phone, #dressCode .hash-sect {
      padding: 20px 0;
    }
  }
  @media (min-width: 768px) {
    #dressCode .contact-phone {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }
  #dressCode .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #9c756e;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  #dressCode .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  #dressCode .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  #dressCode .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  #dressCode .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  #dressCode .php-email-form input, #dressCode .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  #dressCode .php-email-form input:focus, #dressCode .php-email-form textarea:focus {
    border-color: #e0aca2;
  }
  #dressCode .php-email-form input {
    padding: 10px 15px;
  }
  #dressCode .php-email-form textarea {
    padding: 12px 15px;
  }
  #dressCode .php-email-form button[type=submit] {
    background: #e0aca2;
    border: 0;
    padding: 10px 40px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    cursor: pointer;
  }
  #dressCode .php-email-form button[type=submit]:hover {
    background: #9c756e;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #invitados-list {
    padding-left: 0 !important;
  }
  #invitados-list li {
      list-style:none;
  }
  #invitados-list span {
      display:inline-block;
    font-size: 22px;
    line-height: 36px;
      border-bottom: 1px solid #ccc;
      padding: 15px 0;
  }

@media(max-width:1020px){
    .contact-info{
        padding: 0 46px;
    }
    
}

@media(max-width:780px){
    #invitados-list span {
        font-size: 16px;
    }

    .contact-info{
        padding: 0 40px;
    }
    .dress-code{
        /* background-color: #0000c8; */
        height: auto;
        padding: 20px 0;
    }
    .hashtag{
        /* background-color: #393941; */
        /* padding: 0px 20px; */
        height: auto;
        padding: 20px 0;
    }
}

