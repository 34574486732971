/*--------------------------------------------------------------
# Countdown Section
--------------------------------------------------------------*/
#countdown {
  padding: 60px;
  /*background: url(../../assets/subscribe-bg.jpg) center center no-repeat;*/
  background-size: cover;
  overflow: hidden;
  position: relative;
}
#countdown:before {
  content: "";
  background: rgba(6, 12, 34, .3);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#countdown h6{
  color: white;
  font-family: 'Didact Gothic';
  text-transform: uppercase;
  letter-spacing: 5px;
}

@media screen and (max-width: 500px){
  #countdown h6{
    font-size: 18px;
  }

}
.section-header img{
  /* display:block;
  margin:auto; */
  padding: 0;
  margin: 0;
  max-height: 300px;
}

@media (min-width: 1024px) {
  #countdown {
    background-attachment: fixed;
  }
}

#countdown .section-header h2, #countdown p {
  color: #fff;
}

@media (max-width: 460px) {
  #countdown button {
    margin-top: 10px;
  }
}