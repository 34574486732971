/*--------------------------------------------------------------
# BiblicalPhrase
--------------------------------------------------------------*/
#biblical {
    /* background: url("../../assets/about-bg.jpg"); */
    background-size: cover;
    overflow: hidden;
    position: relative;
    color: #fff;
    padding: 60px 0 40px 0;
  }
  @media (min-width: 1024px) {
    #biblical {
      background-attachment: fixed;
    }
  }
  #biblical:before {
    content: "";
    background: rgba(13, 20, 41, 0.3);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  #biblical h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff;
  }
  #biblical h3 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
  }
  #biblical p {
    font-size: 14px;
    margin-bottom: 20px;
    color: #fff;
    font-family: 'Didact Gothic';
    letter-spacing: 1px;
  }