#qr{

    min-height: 100vh;
    display: grid;/*siempre mantene el footer hasta abajo*/
    grid-template-columns: 100vw;/*solo abrá una columna que mantendra el 100% del viewport*/
    /*grid-template-rows: auto 1fr auto;/*solo habran 3 filas, la de arriba y la de abajo con un alto automatico, la de en medio con 1fraccion(va a medir lo que tenga que medir)pero si no tiene contenido suficiente abarca todo el alto disponible*/
    overflow-x: hidden;/*todo lo que se desborde a los lados, ocultalo*/
    position: relative;

    width: 100%;
    background-position: top center;
    background-size: cover;
}

#qr:before {
    content: "";
    background: rgba(6, 12, 34, 0.1);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}
.qrConfirmation{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 16px;
}
.qrConfirmation h2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'parisienne';
    font-size: 40px;
    font-weight: 600;
}
.qrConfirmation h3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'parisienne';
    font-size: 40px;
}

.qrConfirmation-name{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 340px;

}
.qrConfirmation-name h3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-family: 'Cormorant Infant', serif;
    font-weight: 700;
    font-size: 25px;
}

.qr-logo{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}