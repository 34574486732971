/* ---------------------------------
6. REMAINING TIME
--------------------------------- */
.remaining-time-component .time-sec-component{ 
    position: relative; 
    display: inline-block; 
    margin: 0 1px; /*para el saveTheDate- para la invitacion bajar el 10px a 1*/
    margin-bottom: 30px; 
    height: 120px; 
    width: 120px; 
    padding-top: 25px; 
    border-radius: 100px; 
    color: rgb(255, 255, 255);
    animation: pulse 1s ease infinite;
    text-transform: uppercase; 
    letter-spacing: 4px;
    
    
}

.remaining-time-component .time-sec-component .title-component{ 
    /* font-size: 3em;  */
    line-height: 1; 
    display: block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 70px;
    font-weight: 700;
    
}











