/* =======  Bride & Groom style  ======= */
.section-subtitle{
    padding-top: 20px;
    padding-bottom: 10px;
    font-family: 'Alex Brush', cursive;
    font-size: 50px;
    
}
.bride-groom .item {
    padding: 0px;
    border-radius: 0px;
    position: relative;
    overflow: hidden;
}

.bride-groom .img {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto 20px;
}
.bride-groom .info h1 {
    text-align: center;
}
.bride-groom .info h6 {
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'Didact Gothic', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.bride-groom .info h6 i {
    color: #b08c3e;
    font-size: 16px;
}

.bride-groom .info span {
    color: #b08c3e;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Alex Brush', cursive;
    letter-spacing: 1px;
}

  /*styles for text without typewriter, comment if you gonna use typewriter*/
.bride-groom .info p {
margin-top: 10px;
    font-family: 'Didact Gothic';
    letter-spacing: 1px;
}

.Typewriter {
height: 130px;
}

.Typewriter__wrapper {
    margin-top: 10px;
    font-family: 'Didact Gothic';
    letter-spacing: 1px;
}
  
@media screen and (max-width: 991px) {
    .bride-groom .img {
        margin: auto;
        float: none !important;
    }
    .bride-groom .info {
        margin: 30px 0 0 0 !important;
    }
    .bride-groom .item, .bride-groom .toright .info {
        text-align: center;
    }
}
