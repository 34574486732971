/*--------------------------------------------------------------
# Asistencia Section
--------------------------------------------------------------*/
#asistencia {
    /* padding: 60px 0; */
    min-height: 100vh;
    display: grid;/*siempre mantene el footer hasta abajo*/
    grid-template-columns: 100vw;/*solo abrá una columna que mantendra el 100% del viewport*/
    /*grid-template-rows: auto 1fr auto;/*solo habran 3 filas, la de arriba y la de abajo con un alto automatico, la de en medio con 1fraccion(va a medir lo que tenga que medir)pero si no tiene contenido suficiente abarca todo el alto disponible*/
    overflow-x: hidden;/*todo lo que se desborde a los lados, ocultalo*/
    position: relative;

    width: 100%;
    background-position: top center;
    background-size: cover;
    
}


.honor-section{
  font-size: 40px;

}

.honor-section-name{
  font-family: 'Parisienne', cursive;
  font-size: 51px;
  margin: 20px 0;
  margin-bottom: 60px;
}

@media (max-width: 768px) {/*espacio*/
  .honor-section-name{
    margin-bottom: 30px;
  }
}

.contact-info{
    padding: 0 150px;
}


  #asistencia .contact-info {
    margin-bottom: 20px;
    text-align: center;
  }
  #contaasistenciact .contact-info i {
    font-size: 48px;
    display: inline-block;
    margin-bottom: 10px;
    color: #e0aca2;
  }
  #asistencia .contact-info address, #asistencia .contact-info p {
    margin-bottom: 0;
    color: #1f1f22;
  }
  #asistencia .contact-info h3 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
    color: #a8835a;
  }
  #asistencia .contact-info a {
    color: #1f1f22;
  }
  #asistencia .contact-info a:hover {
    color: #e0aca2;
  }
  /* #asistencia .dress-code-sect, #asistencia .contact-phone, #asistencia .hash-sect {
    margin-bottom: 20px;
  } */
  @media (max-width: 768px) {/*espacio*/
    #asistencia .contact-phone {
      padding: 20px 0;
    }
  }
  @media (min-width: 768px) {
    #asistencia .contact-phone {
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }
  #asistencia .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #9c756e;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  #asistencia .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  
  #asistencia .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  #asistencia .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  #asistencia .php-email-form input, #asistencia .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
  }
  #asistencia .php-email-form input:focus, #asistencia .php-email-form textarea:focus {
    border-color: #e0aca2;
  }
  #asistencia .php-email-form input {
    padding: 10px 15px;
  }
  #asistencia .php-email-form textarea {
    padding: 12px 15px;
  }
  #asistencia .php-email-form button[type=submit] {
    background: #b28c5d;
    border: 0;
    padding: 10px 20px;
    color: #fff;
    transition: 0.4s;
    /* border-radius: 50px; */
    cursor: pointer;
  }
  #asistencia .php-email-form button[type=submit]:hover {
    background: #8c6a37;
  }
  .php-email-form{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  #invitados-list {
    padding-left: 0 !important;
  }
  #invitados-list li {
      list-style:none;
  }
  #invitados-list span {
      display:inline-block;
    font-size: 22px;
    line-height: 36px;
      border-bottom: 1px solid #ccc;
      padding: 15px 0;
  }

@media(max-width:1020px){
    .contact-info{
        padding: 0 46px;
    }
    
}

@media(max-width:780px){
    #invitados-list span {
        font-size: 16px;
    }

    .contact-info{
        padding: 0 40px;
    }
}

.name-invitation{
  padding-top: 10px;
}

.name-invitation h2 {
  font-family: 'Cormorant Infant', serif;
}





/* Toogle Switch*/
label i {
    display: inline-block;
    /* float: right; */
    padding: 2px;
    width: 40px;
    height: 20px;
    border-radius: 13px;
    vertical-align: middle;
    transition: 0.25s 0.09s;
    position: relative;
    background: #d8d9db;
    box-sizing: initial;
}
label i:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    left: 2px;
    transition: 0.25s;
}
label > input:checked + i {
    background: #4bd865;
}
label > input:checked + i:after {
    transform: translateX(20px);
}
label:hover {
    cursor: pointer;
}
label{
    margin-left: 16px;
}

.container-qr{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.output-box{
  display: flex;
  flex-direction: column;
  color: #524e4e;
  font-family: 'Didact Gothic';
  margin: 12px;
}
.output-box h3{
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #524e4e;
  font-family: 'Didact Gothic';
  margin: 12px;
}

.output-box img{
  margin: 5px 0;
  height: 285px;
  width: 285px;

}

.qr-code{
  display: flex;
  justify-content: center;
}

.button-qr{
  background: #b28c5d;
  border: 0;
  padding: 10px 20px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;

}

.button-qr[type=button]:hover {
  background: #8c6a37;
}


/* qr */
.help {
  background: #101522;
  color: #eee;
  font-size: 14px;
  bottom: 0;
  height: 63px;
  /* position: absolute; */
  width: 100%;
}

@media (min-width: 500px) {
  .help{
    position: absolute; /*mantener el footer abajo en pantalllas grandes*/
  }
}


.help .help-text {
  text-align: center;
  padding-top: 21px;
}

.help-url{
  color: #b28c5d;
}