/* ---------------------------------
6. REMAINING TIME
--------------------------------- */
.remaining-time .time-sec{ 
    position: relative; 
    display: inline-block; 
    margin: 0 10px; /*para el saveTheDate- para la invitacion bajar el 10px a 1*/
    margin-bottom: 30px; 
    height: 120px; 
    width: 120px; 
    padding-top: 25px; 
    border-radius: 100px; 
    color: rgb(255, 255, 255);
    animation: pulse 1s ease infinite;
    text-transform: uppercase; 
    letter-spacing: 4px;
    
    
}

.remaining-time .time-sec .title{ 
    /* font-size: 3em;  */
    line-height: 1; 
    display: block;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 70px;
    font-weight: 700;
    
}

@media screen and (max-width: 385px){
    .remaining-time .time-sec .title{
        font-size: 50px;
    }
  
}

.es-hoy{
    color: white;
    text-transform: uppercase;
    font-size: 40px;
    font-family: 'Didact Gothic', sans-serif;
    letter-spacing: 6px;
}











