/*--------------------------------------------------------------
# Login Section
--------------------------------------------------------------*/
#login {
    padding: 60px 0;


    width: 100%;
    background-size: cover;
    overflow: hidden;
    position: relative;
}

.login-title{
  font-size: 40px;
}

.login-sub-title{
  font-family: 'Cormorant Infant', serif;
  font-size: 22px;
  margin: 10px 0;
  margin-bottom: 30px;
}

@media (max-width: 768px) {/*espacio*/
  .login-sub-title{
    margin-bottom: 30px;
  }

  .form-control-login{
      width: 50%;
  }
}

.form-control-login{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    /* border-radius: 0.25rem; */
    border-radius: 1.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
}

@media (max-width: 768px) {/*espacio*/
    .login-sub-title{
        font-size: 18px;
    }

    .d-flex{
        display: flex;
        justify-content: center;
    }

    .form-control-login{
        width: 50%;
    }
  }

#login .php-email-form-login button[type=submit] {
background: #b28c5d;
border: 0;
padding: 10px 20px;
color: #fff;
transition: 0.4s;
/* border-radius: 50px; */
cursor: pointer;
}

.submit-number{
    color: #1f1f22;
}

#login .php-email-form-login button[type=submit]:hover {
background: #8c6a37;

}

.php-email-form-login{
display: flex;
justify-content: center;
flex-wrap: wrap;
padding: 10px 0;
}

.mandatory-field{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.mandatory-field p {
  margin: 10px 0 0 0;
  font-family: 'Didact Gothic';
  font-weight: 600;
}